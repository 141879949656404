import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from '../pages/About';
import Home from '../pages/Home';
import Projects from '../pages/Projects';
import Experiences from '../pages/Experiences';

const Routing = () => {
    return (
        <div>
          <Routes>
              <Route path="/" exact element = {<Home/>}></Route>
              <Route path='/about' exact element={<About/>}></Route>
              <Route path="/experience" exact element ={<Experiences/>}></Route>

              <Route path="/projects" exact element = {<Projects/>}></Route>
          </Routes>
          
        </div>  
      )
  }

export default Routing
