import React from 'react';
import ReactPopup from "reactjs-popup";
import "../styling/Popup.css";
import { IoIosClose } from "react-icons/io";

const Popups = ({ Name, TechStack, Image, Links, Desc, isOpen, closeModal }) => {
  const stack = [
    { name: "Javascript", color: "#ADDA44" },
    { name: "React", color: "#61DAFB" },
    { name: "Python", color: "#306998" },
    { name: "TensorFlow", color: "#FF6F00" },
    { name: "HTML/CSS", color: "#D69DFC" },
    { name: "Tailwind CSS", color: "#06B6D4" },
    { name: "CSS", color: "#254BDD" },
    { name: "Github", color: "#A99AB8" },
    { name: "MongoDB", color: "#118D4D" },
    { name: "Docker", color: "#1D63ED" },
    { name: "Cohere", color: "#355146" },
    { name: "NextJS", color: "#A6E7E4" },
    { name: "TypeScript", color: "#087ECE" },
    { name: "Deso", color: "#1A4B9B" },
    { name: "Framer", color: "#F308B1" },
    { name: "Figma", color: "#FF7262" },
    { name: "Flask", color: "#40ABC0" },
    { name: "Pandas", color: "#F7CA22" },
    { name: "OpenAI", color: "#D5C8D6" },
    { name: "Java", color: "#F09217" },
    { name: "SQL", color: "#AAE537" },
    { name: "SpringBoot", color: "#69AD3C" },
    { name: "CodeMirror", color: "#DA8F9E" },
    { name: "AlanAI", color: "#E2743F" },
    { name: "JSON", color: "#939393" },
    { name: "Arduino", color: "#009297" },
    { name: "C++", color: "#4A7CAC" }
  ];

  return (
    <ReactPopup
      className=''
      open={isOpen}
      modal
      nested
      onClose={closeModal}
    >
      {close => (
        <div className='overlay' onClick={close}>
          <div className="modal">
            <div>
              <img className="imgCont" src={Image} alt={Name} />
            </div>
            <div className="header">
              <div className='font-bold'>{Name}</div>
              <div className='border-b-2 text-sm font-normal border-black pb-4 italic mx-10'>{Desc}</div>
            </div>
            <div className='flex flex-wrap ml-10 mr-6 border-b-2 border-black pb-2'>
              {TechStack.map((Tag, index) => {
                const foundTag = stack.find(item => item.name === Tag);
                const tagColor = foundTag ? foundTag.color : "#000";
                return (
                  <div className='m-1 py-1 px-3 rounded-md hover:bg-violet-600' style={{ backgroundColor: tagColor }} key={index}>
                    {Tag}
                  </div>
                );
              })}
            </div>
            <div>
              <ul className='flex justify-center'>
                {Links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a className="mx-16" href={link.url} target='_blank' rel='noopener noreferrer'>{link.icon}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className=''>
              <button
                className='text-red'
                onClick={() => {
                  closeModal();
                  close();
                }}
              >
                <IoIosClose size={30} color='#BDA3A3' />
              </button>
            </div>
          </div>
        </div>
      )}
    </ReactPopup>
  );
};

export default Popups;