import React, { useRef } from 'react'
import { motion } from 'framer-motion'
import Profile from "../styling/icons/profile.png"
import TechStack from '../components/TechStack'
import Cardflip from '../components/Cardflip'
import Carousel from '../components/Carousel'
import { FaHandRock } from "react-icons/fa";
import { FaDumbbell } from "react-icons/fa";
import { MdDraw } from "react-icons/md"
import { FaLeaf } from "react-icons/fa";

const About = () => {
  const emailRef = useRef(null);

  const copyToClipboard = () => {
    if (emailRef.current) {
      navigator.clipboard.writeText(emailRef.current.textContent)
        .then(() => alert("Email copied to clipboard!"))
        .catch(err => console.error("Failed to copy: ", err));
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className='flex flex-wrap items-center justify-center mt-24 mb-24'>
        <motion.div className='w-full md:w-2/5 md:mr-24 flex-wrap px-4 text-center md:text-left' initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.5 }}>
          <div className='font-bold text-white text-2xl'>MORE <span className='text-[#ED4567] mx-2'>ABOUT ME</span></div>
          <div className='mt-4 font-normal text-lg text-white leading-relaxed px-8'>
            <div>👩🏻 Hi, I'm Sophie (she/her), a software developer based in Toronto, Canada. I'm currently studying <span className='text-[#B79E9E] mx-1 hover:text-white'>Systems Design Engineering</span> at the University of Waterloo, specializing in Intelligent and Automated Systems.</div>
            <div className='mt-3'>💡 I create scalable, user-friendly software solutions. My approach combines programming skills with user-centric design principles.</div>
            <div className='mt-3'>🌎 I've attended over 10 hackathons and conferences, focusing on AI trends and sustainable software infrastructure. I'm passionate about designing accessible and impactful technology.</div>
            <div className='mt-3'>
              Let's connect on 
              
              <a
              className='text-[#B79E9E] mx-1 cursor-pointer '
              href="https://www.linkedin.com/in/sophie-yang-66b855216"
              target="_blank"
              >
              LinkedIn</a> or via email at 
              <span 
                ref={emailRef}
                onClick={copyToClipboard}
                className='text-[#B79E9E] mx-1 cursor-pointer '
              >
                s482yang@uwaterloo.ca
              </span>
              to discuss opportunities or just to chat.
            </div>
          </div>
        </motion.div>
        <motion.img className="w-40 rounded-xl mt-4" src={Profile} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.5 }} />
      </div>

      <div className='text-center text-white font-bold text-2xl px-4'>
        What I'm Working on....
      </div>
      <div>
        <Carousel />
      </div>
      <div className='text-center text-white font-bold text-2xl px-4'>
        Professional Skill Set
      </div>
      <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.5 }}>
        <TechStack />
      </motion.div>
      <div className='text-center text-white font-bold text-2xl mt-12 mb-4 px-4'>
        Passing My Time
      </div>
      <motion.div className='flex mb-36 max-w-4xl m-auto flex-wrap items-center justify-center' initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.5 }}>
        <Cardflip icon={<FaLeaf size={50} />} title={"Meditation"} desc={"I've been meditating for around 3 years now, after hearing about it from a GOTrain stranger. It's a way to reconnect with myself in a very loud world. I'm hoping to become more consistent!"} />
        <Cardflip icon={<FaDumbbell size={50} />} title={"Weight Lifting"} desc={"My favourite hobby, if you're seeing this- treat yourself to a workout today! I've never been sad after a workout."} />
        <Cardflip icon={<MdDraw size={50} />} title={"Drawing and Journalling"} desc={"I've been drawing and journalling for as long as I can remember, inspired by my grandpa growing up! I journal every night, and it's my favourite way to wind down."} />
        <Cardflip icon={<FaHandRock size={50} />} title={"Rock Climbing"} desc={"Ignoring the callouses and extreme soreness, bouldering is something I'd love to keep doing! My peak is only a V3, but V4 coming soon!"} />
      </motion.div>
    </motion.div>
  )
}

export default About
