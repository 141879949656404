import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import "react-vertical-timeline-component/style.min.css";

const Experiences = () => {

  const experiences = [
    {
      Id: 0,
      Title: "Innovation Developer under Digital Document Services",
      Location: "Toronto, Ontario, Canada · On-site",
      Desc: "Currently working as an Innovation Developer focusing on Digital Document Services.",
      Date: "Jun 2024 - Present"
    },
    {
      Id: 1,
      Title: "Innovation Developer under API and Automation",
      Location: "Toronto, Ontario, Canada · On-site",
      Desc: "Developed a tool to grade APIs on performance metrics and integrated a full-stack web application with Backstage, React.js, Golang, MongoDB, PostgreSQL, and Apigee into the RBC developer portal with 850+ developers and a catalogue of 3600+ APIs.",
      Date: "Jul 2023 - Aug 2023"
    },
    {
      Id: 2,
      Title: "Assistant Project Manager at Wizeprep",
      Location: "Remote · Hybrid",
      Desc: "Led the High School Expansion initiative, coordinated and executed over 4 project campaigns, facilitated a focus group with 25+ participants, and gathered over 50,000 cumulative impressions across students in Canada.",
      Date: "Nov 2022 - Jun 2023"
    },
    {
      Id: 3,
      Title: "Director of Finance for TurtleHacks",
      Location: "Remote",
      Desc: "Led a team of 6 students for sponsorship outreach/coordination, created budget projections, and managed finance-related matters. Secured 2 in-person sponsorship agreements and a $4000 budget.",
      Date: "Jul 2022 - May 2023"
    },
    {
      Id: 4,
      Title: "Director of Technology for PetHacks",
      Location: "Remote",
      Desc: "Led a small group of developers to design and develop the website for PetHacks.",
      Date: "Aug 2022 - Dec 2022"
    }
  ];

  // Function to colorize numbers in text
  const highlightNumbers = (text) => {
    const regex = /(\d+)/g;
    return text.replace(regex, '<span class="text-[#ED4567]">$1</span>');
  };

  return (
    <div className='px-4 py-8'>
      <div className='text-center text-white font-semibold text-2xl mt-12 mb-8'>Experiences</div>
      <VerticalTimeline>
        {experiences.map((experience) => (
          <VerticalTimelineElement
            key={experience.Id}
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#BDA3A3', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #fff' }}
            date={experience.Date}
            iconStyle={{ background: '#BDA3A3', color: '#BDA3A3' }}
          >
            <div className="text-black font-semibold">{experience.Title}</div>
            <h4 className="text-black">{experience.Location}</h4>
            <p dangerouslySetInnerHTML={{ __html: highlightNumbers(experience.Desc) }} />
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  )
}

export default Experiences
