import React, {useState} from 'react'
import ReactCardFlip from 'react-card-flip'

const Cardflip = ({icon, title, desc}) => {

    const [isFlipped, setIsFlipped] = useState()
    const handleClick =(e) => {
        e.preventDefault()
        setIsFlipped(prevState => !prevState)
    }



    return (
        <div className="" >
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" flipSpeedBackToFront={1} flipSpeedFrontToBack={1}>            
            <button className=" bg-[#B1A2A2] text-black rounded-xl mx-4 h-64 mt-6 w-48" onClick={handleClick}>
            <div className='flex items-center justify-center mx-4 py-20 border-2 rounded-2xl'>{icon}</div>
            </button>
            <button className="bg-[#B1A2A2] text-black mx-4 rounded-xl h-64 w-48 mt-6 flex flex-col" onClick={handleClick}>
            <div className='font-bold border-b-2 border-white mx-6 text-center ml-10 mt-6'>{title}</div>
            <div className='text-sm mx-4 mt-4'>{desc}</div>
          </button>
        </ReactCardFlip>
        </div>
      );
    }

export default Cardflip
