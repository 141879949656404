import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import "../styling/Carousel.css"

//thumbnails
import CourseWare from "../styling/carouselThumbnails/courseware.png"
import Hackathon from "../styling/carouselThumbnails/hackathon.png"
import Hardware from "../styling/carouselThumbnails/hardware.png"
import Leetcode from "../styling/carouselThumbnails/leetcode.png"
import MLearning from "../styling/carouselThumbnails/machinelearning.png"

function Carousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const slides = [
    {
      image: CourseWare,
      title: "Courses!",
      desc: "Currently taking CS50's Introduction to Artificial Intelligence with Python by Brian Yu"
    },
    {
      image: Hackathon,
      title: "FraserHacks",
      desc: "Organizing FraserHacks as the Lead Organizer. The hackathon is set to take place in May 2024, as GTA's largest education-centered hackathon!"
    },
    {
      image: Hardware,
      title: "Hardware",
      desc: "Getting into robotics has always been a goal of mine- I recently got a beginner's arduino kit, so I've been building small projects!"
    },
    {
      image: Leetcode,
      title: "Leetcode",
      desc: "As an avid problem solver, I've always enjoyed Leetcode. What started out as practicing for CCC now involves me learning new algorithms on weekends at cafes."
    },
    {
      image: MLearning,
      title: "ML/AI",
      desc: "How could I not?! Too cool not to :)"
    },
  ];

  return (
    <div className='mt-8 mb-20'>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 30,
            marginLeft: '20px',
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 30,
            marginRight: '20px',
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
      >
        {slides.map(({ image, title, desc }, index) => (
          <div key={index} className='slide relative border-white border-2'>
            <div className='bg-black w-full h-full'>
              <img className="w-full h-full object-cover rounded-md opacity-30" src={image} alt={title} />
            </div>
            <div className='absolute inset-0 flex flex-col text-center justify-center items-center p-4'>
              <div className='text-white text-xl font-semibold pb-2 border-b-2 border-white w-3/4'>{title}</div>
              <div className='text-white pt-2 text-sm sm:text-base'>{desc}</div>
            </div>
          </div>
        ))}
      </ReactSimplyCarousel>
    </div>
  );
}

export default Carousel;
