import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Routing from './components/Routing';
import ParticlesComp from './components/Particles';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className={`min-h-screen flex flex-col ${loading ? 'bg-[#171212] justify-center items-center' : ''}`}>
      {loading ? (
        <div className="text-center">
          <ClimbingBoxLoader
            color={"#B79E9E"}
            size={15}
            loading={loading}
          />
        </div>
      ) : (
        <div className="flex flex-col flex-grow">
          <ParticlesComp id="particles" />
          <BrowserRouter>
            <Navbar />
            <main className="flex-grow">
              <Routing />
            </main>
            <Footer />
          </BrowserRouter>
        </div>
      )}
    </div>
  );
}

export default App;
