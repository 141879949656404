import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full h-24 bg-black text-white flex flex-col items-center justify-center">
      <div className="text-center">
        Made with 💜 by Sophie @ 2024
      </div>
      <a
        className="text-center text-white hover:text-[#ED4567]"
        href="https://purple-hyacinth-51.tiiny.site"
        target="_blank"
        rel="noopener noreferrer"
      >
        View Resume
      </a>
    </footer>
  );
};

export default Footer;
