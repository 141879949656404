import React from 'react';
import { IconContext } from 'react-icons/lib';
import { TbBrandMongodb, TbBrandTailwind, TbBrandJavascript, TbBrandPython, TbBrandMysql, TbBrandGolang, TbBrandUnity } from "react-icons/tb";
import "../styling/Techstack.css"

const TechStack = () => {
    const stack = [
        {
            name: "Mongo",
            logo: <TbBrandMongodb />,
        },
        {
            name: "Tailwind",
            logo: <TbBrandTailwind />,
        },
        {
            name: "Javascript",
            logo: <TbBrandJavascript />,
        },
        {
            name: "Python",
            logo: <TbBrandPython />,
        },
        {
            name:"SQL",
            logo: <TbBrandMysql/>
        },
        {
            name:"Golang",
            logo: <TbBrandGolang/>
        },
        {
            name:"Unity",
            logo: <TbBrandUnity/>
        },
    ];
    return (
        <div className='flex flex-wrap items-center justify-center mx-36 '>
            {stack.map(({ name, logo }, index) => (
                <div key={index} className="stack">
                    <div className="flex items-center justify-center mb-3 mt-2">
                        <IconContext.Provider value={{ color: "white", size: "7em" }}>
                            {logo}
                        </IconContext.Provider>
                    </div>
                    <div className='text-center text-white'>{name}</div>
                </div>
            ))}
        </div>
    );
}

export default TechStack;
