import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
import Github from '../styling/icons/github.svg';
import LinkedIn from '../styling/icons/linkedin.svg';
import Mail from '../styling/icons/mail.svg';
import '../styling/Home.css';
import Planet from "../styling/icons/planet.png";
import Star from "../styling/icons/star1.png";
import Comet from "../styling/icons/comet.png";
import Profile from "../styling/icons/profile.png";
import { motion } from "framer-motion";

const Home = () => {
  const navigate = useNavigate();

  const goToNewPage = () => {
    navigate("/about");
    window.scrollTo(0, 0);
  };

  return (
    <div className="w-full">
      {/* First Section: Hello world */}
      <motion.div 
        className="flex flex-col md:flex-row items-center justify-center w-full min-h-screen px-6 sm:px-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <div className='flex flex-col items-center md:items-start md:mr-10 text-center md:text-left'>
          <div className="text-2xl text-[#BDA3A3] mb-2">Hello world,</div>
          <div className="text-[#fff] text-5xl md:text-7xl flex flex-wrap justify-center md:justify-start">
            I'm <span className='name text-[#ed4567] mx-2 hover:text-white'>Sophie</span> Yang.
          </div>
          <div className="text-xl text-[#BDA3A3] mb-4 mt-2">
            <Typewriter
              options={{
                strings: [],
                autoStart: true,
                loop: true,
                delay: 110,
              }}
              onInit={(typewriter) => {
                typewriter
                  .pauseFor(1000)
                  .typeString('Hackathon Enthusiast')
                  .deleteAll()
                  .pauseFor(1000)
                  .typeString('Builder')
                  .pauseFor(1000)
                  .deleteAll()
                  .typeString('Student');
              }}
            />
          </div>
          <div className="socialLinks flex space-x-4 justify-center md:justify-start">
            <a className="logo" href="https://github.com/bakuyy" target="_blank" rel="noopener noreferrer">
              <img src={Github} alt="Github" />
            </a>
            <a className="logo" href="https://www.linkedin.com/in/sophie-yang-66b855216" target="_blank" rel="noopener noreferrer">
              <img src={LinkedIn} alt="LinkedIn" />
            </a>
            <a className="logo" href="mailto:your-email@example.com" target="_blank" rel="noopener noreferrer">
              <img src={Mail} alt="Email" />
            </a>
          </div>
        </div>
        <img className="tempLogo mt-8 md:mt-0 w-36 h-36 md:w-48 md:h-48" src={Planet} alt="Rando Logo" />
      </motion.div>

      {/* Second Section: Let me introduce myself */}
      <div className="relative w-full min-h-screen flex flex-col items-center justify-center px-4 sm:px-8">
        <img className="design absolute w-24 h-24 opacity-20 animate-bounce" src={Comet} alt="Comet" style={{ top: '6rem', left: '10%' }} />
        <img className="design absolute w-20 h-20 opacity-20 animate-bounce" src={Star} alt="Star" style={{ top: '10rem', left: '40%' }} />
        <img className="design absolute w-20 h-20 opacity-20 animate-bounce" src={Star} alt="Star" style={{ top: '5rem', right: '40%' }} />
        <img className="design absolute w-20 h-20 opacity-20 animate-bounce" src={Star} alt="Star" style={{ top: '15rem', right: '20%' }} />

        <div className='flex flex-col md:flex-row justify-center items-center w-full'>
          <div className='text-center md:text-left w-full md:w-1/2 md:mr-10'>
            <div className='font-bold text-white text-3xl md:text-4xl flex flex-wrap justify-center md:justify-start'>
              LET ME <span className='text-[#ED4567] mx-2'>INTRODUCE</span> MYSELF
            </div>
            <div className='mt-2 text-md md:text-lg text-white font-normal'>
              I'm a First Year <span className='text-[#B79E9E] mx-1 hover:text-white'>Systems Design Engineering Student</span> at Waterloo and a driven full-stack developer. I'm from Toronto, Canada.
            </div>
            <div className='mt-3 text-white'>
              I often work with <span className='text-[#B79E9E] mx-1 hover:text-white'>Javascript, Python, and Golang</span> - I'm looking to gear my projects towards machine learning and artificial intelligence!
            </div>
            <button className="text-[#B79E9E] border-b-2 border-white hover:text-white mt-4" onClick={goToNewPage}>Learn more</button>
          </div>
          <img className="profileImg w-36 h-36 rounded-3xl mt-8 md:mt-0" src={Profile} alt="Profile" />
        </div>
      </div>
    </div>
  );
};

export default Home;
