import React, {useState} from 'react';
import "../styling/Project.css";
import Popups from './Popups';

const Project = ({ Name, TechStack, Image, Links, Id, Desc, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

    const stack = [
        { name: "Javascript", color: "#ADDA44" },
        { name: "React", color: "#61DAFB" },
        { name: "Python", color: "#306998" },
        { name: "TensorFlow", color: "#FF6F00" },
        { name: "HTML/CSS", color: "#D69DFC" },
        { name: "Tailwind CSS", color: "#06B6D4" },
        { name: "CSS", color: "#254BDD" },
        { name: "Github", color: "#A99AB8" },
        { name: "MongoDB", color: "#118D4D" },
        { name: "Docker", color: "#1D63ED" },
        { name: "Cohere", color: "#355146" },
        { name: "NextJS", color: "#A6E7E4" },
        { name: "TypeScript", color: "#087ECE" },
        { name: "Deso", color: "#1A4B9B" },
        { name: "Framer", color: "#F308B1" },
        { name: "Figma", color: "#FF7262" },
        { name: "Flask", color: "#40ABC0" },
        { name: "Pandas", color: "#F7CA22" },
        { name: "OpenAI", color: "#D5C8D6" },
        { name: "Java", color: "#F09217" },
        { name: "SQL", color: "#AAE537" },
        { name: "SpringBoot", color: "#69AD3C" },
        { name: "CodeMirror", color: "#DA8F9E" },
        { name: "AlanAI", color: "#E2743F" },
        { name: "JSON", color: "#939393" },
        { name: "Arduino", color: "#009297" },
        { name: "C++", color: "#4A7CAC" }
    ];
    
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div 
      className="project relative bg-gray-800 p-4 rounded-lg shadow-md cursor-pointer"
      onClick={openModal} // Open popup on clicking anywhere within the Project component
    >
      <div className='imgContainer'>
        <img className='imgLogo' src={Image} alt={Name} />
      </div>
      <div className='mt-4'>
        <div className='projectTitle text-white font-semibold text-lg'>
          {Name}
        </div>
        <div className='flex flex-wrap mt-2'>
          {TechStack.map((Tag, index) => {
            const foundTag = stack.find(item => item.name === Tag);
            const tagColor = foundTag ? foundTag.color : "#000";
            return (
              <div
                className='m-0.5 py-1 px-3 rounded-xl text-sm text-white font-semibold'
                style={{ backgroundColor: tagColor }}
                key={index}
              >
                {Tag}
              </div>
            );
          })}
        </div>
        {/* Incorporate Popups component here */}
        <Popups
          Name={Name}
          TechStack={TechStack}
          Image={Image}
          Links={Links}
          Desc={Desc}
          id={Id}
          isOpen={isOpen}
          closeModal={closeModal}
        />
      </div>
    </div>
  );
};

export default Project;