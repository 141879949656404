import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isTop, setIsTop] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const links = [
    { name: 'Home', link: '/', id: 'home' },
    { name: 'About', link: '/about', id: 'about' },
    { name: 'Experience', link: '/experience', id: 'experience' },
    { name: 'Projects', link: '/projects', id: 'projects' },
  ];

  const handleLinkClick = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsTop(scrollTop === 0);
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <div
        className={`fixed top-0 w-full flex justify-between items-center px-16 pt-6 transition-all duration-300 ease-in-out ${
          isTop ? 'bg-[#171212] backdrop-blur-sm rounded-xl z-30' : 'bg-[#171212] bg-opacity-80 backdrop-blur-none h-16 z-30'
        }`}
      >
        <div className="text-xl ml-2">
          <Link className="text-white font-bold" to="/">S.Y</Link>
        </div>
        <div className="flex items-center space-x-4">
          <button
            className="border-none py-1 rounded text-gray-200 opacity-75 hover:opacity-100 cursor-pointer md:hidden lg:hidden"
            aria-label="Menu"
            data-test-id="navbar-menu"
            onClick={toggleMenu}
          >
            <FaBars size={20} color="#BDA3A3" />
          </button>
          <div className={`hidden md:flex lg:flex items-center space-x-4`}>
            {links.map(({ name, link }) => (
              <Link
                key={name}
                to={link}
                onClick={handleLinkClick}
                className="text-white text-lg p-2 lg:px-4 rounded"
              >
                {name}
              </Link>
            ))}
          </div>
        </div>
      </div>

      {showMenu && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-[#171212] z-50">
          <button
            className="absolute top-6 right-6 border-none py-1 rounded text-gray-200 opacity-75 hover:opacity-100 cursor-pointer"
            aria-label="Close Menu"
            data-test-id="close-menu"
            onClick={toggleMenu}
          >
            <FaBars size={20} color="#BDA3A3" />
          </button>
          {links.map(({ name, link }) => (
            <Link
              key={name}
              to={link}
              onClick={handleLinkClick}
              className="text-lg p-2 text-white z-50 lg:px-4 rounded"
            >
              {name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Navbar;
