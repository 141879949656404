import React from 'react'
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useEffect, useMemo, useState } from "react";

import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.

const ParticlesComp = (props) => {

    const [init, setInit] = useState(false);
    useEffect(() => {
      initParticlesEngine(async (engine) => {
        await loadSlim(engine);
      }).then(() => {
        setInit(true);
      });
    }, []);
  
    const particlesLoaded = (container) => {
      console.log(container);
    };
  
  
    const options = useMemo(
      () => ({
        background: {
          color: {
            value: "#171212",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            // onHover: {
            //   enable: true,
            //   mode: 'grab',
            // },
            
          },
          modes: {
            grab: {
              distance: 150,
            },
          },
        },
        particles: {
          color: {
            value: "#FFFFFF",
          },
          // links: {
          //   color: "#FFFFFF",
          //   distance: 150,
          //   enable: true,
          //   opacity: 0.8,
          //   width: 0.2,
          // },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: true,
            speed: 0.3,
            straight: false,
          },
          number: {
            density: {
              enable: true,
            },
            value: 120,
          },
          opacity: {
            value: 0.6,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 3},
          },
        },
        detectRetina: true,
      }),
      [],
    );
  
  
    return <Particles id={props.id} init={particlesLoaded} options={options} />; 
  };

export default ParticlesComp
